/**
 * Adding warning for IE < 11 and other older browsers
 */

import $ from "jquery";
import Modernizr from "modernizr";

const warning = `
<p>It looks like you\'re using an out-dated web browser.
  Please use a modern browser such as
  <a href=\"https://www.google.com/chrome/browser/desktop/index.html\" target=\"_self\">Google Chrome</a>
  or <a href=\"https://www.mozilla.org/en-US/firefox/new/\" target=\"_self\">Mozilla Firefox</a>.</p>`;

$(function () {
  if (!Modernizr.flexbox) {
    $('<div>')
      .addClass('browser-warning')
      .html(warning)
      .prependTo('.layout--main--container');
  }
});
